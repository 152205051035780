import Vue from 'vue';
import $axios from 'axios';
import router from '@/router/index';

export const axios = $axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/partner',
  transformRequest: [function(data, headers) {
    headers['Authorization'] = `Bearer ${ localStorage.getItem('auth.accessToken') }`;
    return JSON.stringify(data);
  }],
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'pt-br',
  },
});

axios.interceptors.response.use((response) => {
  return response;
}, function(error) {
  if(typeof error.response.status !== 'undefined'){
    if (error.response.status === 404) {
      router.push('/400');
    }

    if (error.response.status === 403) {
      router.push('/500');
    }

    if (error.response.status === 401) {
      let config = JSON.parse(localStorage.getItem("vuex"));
      config.token = "";

      localStorage.setItem("vuex", JSON.stringify(config));
      router.push('/400');
    }

    if (error.response.status === 409) {
      throw "Ocorreu um conflito ao processar a requisição. Favor verificar se os dados inseridos já não estão cadastrados e tentar novamente.";
    }
  }else{
    router.push('/error');
  }

  if(typeof error.response.data.message !== 'undefined'){
    throw error.response.data.message;
  }else{
    throw "Ocorreu um erro durante o processamento da requisição. Favor tentar novamente.";
  }
});

Vue.prototype.$axios = axios;
