/**
 *
 * Partner
 * @class Partner
 */

export class Partner {

  /**
     * Creates an instance of Partner.
     * @param { PartnerObj } partnerObj
     * @memberof Partner
     */

  constructor(partnerObj) {
    if(typeof partnerObj === 'undefined' || partnerObj === null){
      partnerObj = {};
    }

    this.id       = partnerObj.id || null;
    this.name     = partnerObj.name || null;

    this.name                = partnerObj.name || null;
    this.business_name       = partnerObj.business_name || null;
    this.email               = partnerObj.email || null;
    this.nif                 = partnerObj.nif || null;
    this.currency            = partnerObj.currency || 'brl';
    this.code                = partnerObj.code || null;
    this.street              = partnerObj.street || null;
    this.number              = partnerObj.number || null;
    this.complement          = partnerObj.complement || null;
    this.district            = partnerObj.district || null;
    this.zipcode             = partnerObj.zipcode || null;
    this.city                = partnerObj.city || null;
    this.state               = partnerObj.state || null;
    this.phone               = partnerObj.phone || null;
    this.phone_number        = partnerObj.phone_number || null;
    this.phone_prefix        = partnerObj.phone_prefix || '+55';
    this.active              = partnerObj.active || 1;
    this.locale              = partnerObj.locale || null;

  }

  /**
     * Creates an instance of Partner.
     * @param { PartnerObj } partnerObj
     * @memberof Partner
     * @returns { Partner }
     */
  static init(partnerObj) {
    return new Partner(partnerObj);
  }

}
