<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { getPartner } from '@/api/Partners.js'

import { Partner } from "@/models/Partner";

export default {
  components: {},
  data() {
    return {
      partner: new Partner(),
      ui:{
        alert: {
          success: false,
          error: false
        }
      }
    };
  },
  mounted: function() {
    this.fetchData();
    
    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    fetchData() {
      let vm = this
      getPartner().then(response => {
        vm.partner = new Partner(response)
      })
    },
    copyUrl () {
      let url = document.querySelector('#url')
      url.setAttribute('type', 'text')
      url.select()

      try {
        document.execCommand('copy');
        this.ui.alert.success = true
      } catch (err) {
        this.ui.alert.error = true
      }
      
      window.getSelection().removeAllRanges()
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul
      id="side-menu"
      class="metismenu list-unstyled"
    >
      <li class="menu-title">
        Menu
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/"
          class="side-nav-link-ref"
        >
          <i class="bx bx-home-circle" />
          <span>Dashboard</span>
        </router-link>
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/companies"
          class="side-nav-link-ref"
        >
          <i class="bx bx-buildings" />
          <span>Empresas</span>
        </router-link>
      </li>

      <li>
        <a
          href="javascript: void(0);"
          class="has-arrow"
        >
          <i class="bx bx-calendar-event" />
          <span>Assinaturas</span>
        </a>
        <ul
          class="sub-menu"
          aria-expanded="false"
        >
          <li>
            <router-link
              tag="a"
              to="/subscriptions"
              class="side-nav-link-ref"
            >
              Todas
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              to="/subscriptions/due"
              class="side-nav-link-ref"
            >
              Vencidas
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              to="/subscriptions/cancellations"
              class="side-nav-link-ref"
            >
              Cancelamentos
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-title">
        Financeiro
      </li>

      <li>
        <router-link
          tag="a"
          to="/orders"
          class="side-nav-link-ref"
        >
          <i class="bx bx-barcode" />
          <span>Faturas</span>
        </router-link>
      </li>
  
      <li>
        <a
          href="javascript: void(0);"
          class="has-arrow"
        >
          <i class="bx bx-credit-card" />
          <span>Pagamentos</span>
        </a>
        <ul
          class="sub-menu"
          aria-expanded="false"
        >
          <li>
            <router-link
              tag="a"
              to="/payments"
              class="side-nav-link-ref"
            >
              Todos
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              to="/payments/failed"
              class="side-nav-link-ref"
            >
              Com Falha
            </router-link>
          </li>
        </ul>
      </li>

      <li>
        <router-link
            tag="a"
            to="/funds"
            class="side-nav-link-ref"
        >
          <i class="bx bx-wallet" />
          <span>Fundos</span>
        </router-link>
      </li>

      <li>
        <router-link
            tag="a"
            to="/sales"
            class="side-nav-link-ref"
        >
          <i class="bx bx-bar-chart-square" />
          <span>Vendas</span>
        </router-link>
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/bank-accounts"
          class="side-nav-link-ref"
        >
          <i class="bx bxs-bank" />
          <span>Contas Bancárias</span>
        </router-link>
      </li>
      
      <li>
        <router-link
          tag="a"
          to="/withdrawals"
          class="side-nav-link-ref"
        >
          <i class="bx bx-upload" />
          <span>Saques</span>
        </router-link>
      </li>
  
      <li class="menu-title">
        Administrativo
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/notifications"
          class="side-nav-link-ref"
        >
          <i class="bx bx-bell" />
          <span>Notificações</span>
        </router-link>
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/users"
          class="side-nav-link-ref"
        >
          <i class="bx bx-group" />
          <span>Usuários</span>
        </router-link>
      </li>
  
      <li>
        <router-link
          tag="a"
          to="/profile"
          class="side-nav-link-ref"
        >
          <i class="bx bx-user" />
          <span>Perfil</span>
        </router-link>
      </li>
  
      <li>
        <a
          href="javascript: void(0);"
          class="has-arrow"
        >
          <i class="bx bx-link" />
          <span>Parceiro</span>
        </a>
        <ul
          class="sub-menu"
          aria-expanded="false"
        >
          <li>
            <router-link
              tag="a"
              to="/partner"
              class="side-nav-link-ref"
            >
              Dados
            </router-link>
          </li>
          <li>
            <a
              href="javascript: void(0);"
              class="has-arrow"
              @click="$bvModal.show('modal-link')"
            >
              <span>Link de Afiliado</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  
    <b-modal
      id="modal-link"
      centered
      title="Link de afiliado"
      title-class="font-18"
      hide-footer
    >
      <b-alert v-model="ui.alert.success" variant="success" dismissible>
        Link copiado com sucesso!
      </b-alert>
  
      <b-alert v-model="ui.alert.error" variant="danger" dismissible>
        Ocorreu um erro ao tentar copiar o link de afiliado.
      </b-alert>
      
      <b-input-group>
        <b-form-input id="url" type="text" :value="`https://partner.huggy.io/p/${ partner.code }`" readonly></b-form-input>
        
        <b-input-group-append>
          <b-button variant="primary" @click.stop.prevent="copyUrl()"><i class="bx bx-copy"></i> Copiar</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-modal>
  </div>
  <!-- Sidebar -->
</template>
